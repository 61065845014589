export const environment = {
  production: false,
  baseUrl: 'https://ai-staging.neurun.com/api',
  wsUrl: 'https://ai-staging.neurun.com',
  keycloakUrl: 'https://auth.neurun.com/auth',
  keycloakRealm: 'neurun',
  keycloakClientId: 'neurun_client',
  keycloakClientSecret: 'af5o0bYG4Of3HbtkedBdVjv8UgWnqkOx',
  googleMapsAPIKey: 'AIzaSyDsoZfoY75xr-6bUPgVNrsgFsJ59u0Yohw',
  runkeeper: {
    auth: {
      client_id: '6f4f6f04c5e948e38cb436f6fa40aba9',
      client_secret: '1c8e0c05231f4744adcfe0b5ab5ebdf1',
      authorizationUrl: 'https://runkeeper.com/apps/authorize',
      tokenEndpoint: 'https://ai-staging.neurun.com/api/rk/apps/token',
      profileEndpoint: 'https://ai-staging.neurun.com/api/rk/apps/profile',
      deauthorizeUrl: 'https://ai-staging.neurun.com/api/rk/apps/de-authorize',
    },
  },
  strava: {
    auth: {
      issuer: 'https://www.strava.com',
      client_id: '134598',
      client_secret: '8085a7e3aaa6c4de34c3c7523c54c290c56500f6',
      authorizationUrl: 'https://www.strava.com/oauth/authorize',
      tokenEndpoint: 'https://ai-staging.neurun.com/api/st/oauth/token',
      deauthorizeUrl: 'https://ai-staging.neurun.com/api/st/oauth/deauthorize',
      scope: 'activity:read',
    }
  }
};
